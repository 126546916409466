<template>
  <ClientOnly>
    <div class="absolute inset-x-0 bottom-0 flex justify-center">
      <button
        class="mb-3 flex flex-col items-center text-center text-xs 2xl:text-sm"
        @click="scrollDown"
      >
        <span v-if="text">
          {{ text }}
        </span>

        <span
          class="mt-2 flex size-6 items-center justify-center rounded-sm bg-white text-black"
        >
          <IconAngleDown class="w-3" />
        </span>
      </button>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
const { y } = useWindowScroll()

defineProps<{
  text?: string
}>()

const scrollDown = () => {
  y.value = window.innerHeight * 0.9
}
</script>
